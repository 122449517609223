var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", { staticClass: "overflow-hidden" }, [
    _c("div", { attrs: { slot: "no-body" }, slot: "no-body" }, [
      _c(
        "div",
        {
          staticClass: "p-4",
          class: {
            "flex justify-between flex-row-reverse items-center": _vm.iconRight,
            "text-center": !_vm.iconRight && _vm.hideChart,
            "pb-0": !_vm.hideChart,
          },
        },
        [
          _c("feather-icon", {
            staticClass: "p-3 inline-flex rounded-full",
            class: [`text-${_vm.color}`, { "mb-4": !_vm.iconRight }],
            style: { background: `rgba(var(--vs-${_vm.color}),.15)` },
            attrs: { icon: _vm.icon },
          }),
          _c("div", { staticClass: "truncate" }, [
            _c(
              "h2",
              {
                class: [
                  _vm.textColor !== "empty" ? `text-${_vm.textColor}` : "",
                  "mb-1 font-bold",
                ],
              },
              [_vm._v(_vm._s(_vm.statistic))]
            ),
            _c("span", [_vm._v(_vm._s(_vm.statisticTitle))]),
          ]),
        ],
        1
      ),
      !_vm.hideChart
        ? _c(
            "div",
            { staticClass: "line-area-chart" },
            [
              _c("vue-apex-charts", {
                ref: "apexChart",
                attrs: {
                  type: _vm.type,
                  height: "100",
                  width: "100%",
                  options: _vm.chartOptions,
                  series: _vm.chartData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }